var hymne = document.querySelector('#menue__hymne');
var isPlaying = false;

hymne.addEventListener('click', (event) => {
    
    if (isPlaying == false) {
        // Play the beat
        beat.play();
        console.log(isPlaying)
    } else {
        // Stop the beat
        beat.pause();
        console.log(isPlaying)
    }
    
    isPlaying = !isPlaying;

});
